
import React, { useEffect, useState } from 'react'
import Slider  from 'react-slick';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { Link } from 'react-router-dom';

export const Opensource = () => {
    const [categories,setCategories] = useState({});
    const data = [{
        id:1,
        img:'Cloudflare-Logo.wine.png'
        },
        {
            id:2,
            img:'docker.png' 
        },
        {
            id:3,
            img:'postgresql.png' 
        },
        {
            id:4,
            img:'redis.png' 
        },
        {
            id:6,
            img:'kubernetes.png' 
        },
        {
            id:7,
            img:'cilium-seeklogo.svg'
        },
        {
            id:8,
            img:'grafana-seeklogo.svg'
        },
        {
            id:9,
            img:'prometheus-monitoring-system-seeklogo.svg'
        }
        
] //useContext(rootDataContext);
    
    //const categories = cats.cats.data
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        arrows:false
      };


      const settings2 = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 4000,
        fade: true
      };

    const setCategoryData = async () => {
        try{
            setCategories(data)
        }catch(err){

        }
    }

    useEffect(()=>{
        setCategoryData();
    },[]) 
    
    //WHen the parent component renders a second time, the context will trigger a rerender and update the value
    useEffect(()=>{
        console.log(data)
        console.log("categories set")
    },[data])
    return (
      /*<div className='w-full bg-blue flex relative'>*/
      <div className='h-3/4 w-[vw50]'>
        <div className=' h-[100%]  flex items-center justify-center'>
            <Slider className='h-[20%] w-[95%]' {...settings}>
            {data?data.map((d) => 
                <div className='flex-col' key={d.id}>
                    <div className='h-[90%] w-[90%] flex text-center items-center justify-center'>
                        <img className='h-[45%] w-[50%] items-center' src={d.img}>
                        </img>
                    </div>
                    
                </div>
                
                ):""}
                {/*data?data.map((d) => 
                <div className='flex-col shadow' key={d.id}>
                    <div className='h-[90%] w-[90%] flex items-center justify-center'>
                        <img className='sm:h-[35%] sm:w-[98%]' src={d.img}>
                        </img>
                        {<p className='absolute top-3/4 text-white'>{d.capt}</p>}
                    </div>
                </div>
                
                ):""*/}
            </Slider>
        </div>
      </div>
    )
  }
