import React from 'react'
import { useState } from 'react';
import {AiOutlineClose, AiOutlineMenu,AiFillTag,AiOutlineLogout} from 'react-icons/ai';
import {FaHome,FaCalendar,FaShoppingBag} from 'react-icons/fa';
import services from '../data/services';

import { Link } from "react-router-dom";
//030527 0C213E
export const Navbar = () => {
    //sm navbar state 
    const [nav,setNav] = useState(false)
    const [prodNav,setProdNav] = useState(false)
    const [aiNav,setAINav] = useState(false)
    const [solutionsNav,setSolutionsNav] = useState(false)
    const [resourcesNav,setResourcesNav] = useState(false)
    const [PartnersNav,setPartnersNav] = useState(false)

  return (
    <div className='relative '>
    <div className=' z-50  border-b-[1px] border-b-[gray] sticky top-0 w-[100%] flex md:justify-evenly items-center p-2 mb-4 justify-between'>
        
        <div className='flex flex-col'>
            <div className='text-sm  text-[#3c70b1] font-bold md:text-2xl w-[40%] text-left font-mono'>Redabyte</div>
            <div className='text-[#3c70b1]'>Cloud-native technologies Xperts</div>
        </div>
        
        {/*for large screen devices*/}
        <div className='font-mono hidden md:flex text-[#3c70b1] w-[60%] justify-between items-center'>
            <Link className='relative text-md rounded-md border cursor-pointer text-[#3c70b1] border-slate-300  px-4 py-2 font-mono shadow-sm hover:border-indigo-300 dark:border-zinc-700 dark:text-emerald-400 dark:hover:border-emerald-400' >About Us

            </Link>
            <Link className='relative text-md rounded-md border cursor-pointer text-[#3c70b1]
             border-slate-300  px-4 py-2 font-mono shadow-sm hover:border-indigo-300 dark:border-zinc-700
              dark:text-emerald-400 dark:hover:border-emerald-400'onMouseOver={(e)=>setSolutionsNav(true)} onMouseLeave={(e)=>setSolutionsNav(false)}>Solutions
            </Link>
            <Link className='relative text-md rounded-md border cursor-pointer text-[#3c70b1] border-slate-300  
            px-4 py-2 font-mono shadow-sm hover:border-indigo-300 dark:border-zinc-700 dark:text-emerald-400 dark:hover:border-emerald-400' 
            onMouseOver={(e)=>setProdNav(true)} onMouseLeave={(e)=>setProdNav(false)}
            >Products
                
            </Link>
            <Link className='relative text-md rounded-md border cursor-pointer text-[#3c70b1] border-slate-300  
            px-4 py-2 font-mono shadow-sm hover:border-indigo-300 dark:border-zinc-700 dark:text-emerald-400 dark:hover:border-emerald-400' 
            onMouseOver={(e)=>setResourcesNav(true)}  onMouseLeave={(e)=>setResourcesNav(false)}>Resources
                
            </Link>
            <Link className='relative text-md rounded-md border cursor-pointer text-[#3c70b1] border-slate-300  px-4 py-2 font-mono shadow-sm hover:border-indigo-300 dark:border-zinc-700 dark:text-emerald-400 dark:hover:border-emerald-400' onMouseOver={(e)=>setPartnersNav(true)}  onMouseLeave={(e)=>setPartnersNav(false)}>Support
                
            </Link>
            <Link class="text-md rounded-md border cursor-pointer text-[#3c70b1] border-slate-300  px-4 py-2 font-mono shadow-sm hover:border-indigo-300 dark:border-zinc-700 dark:text-emerald-400 dark:hover:border-emerald-400">Contact us</Link>
        </div>
        {/*for small screen devices*/}
        <div className='md:hidden cursor-pointer text-white'>
            <AiOutlineMenu size={20} onClick={()=>{setNav(!nav)}}/>
        </div>
        <div className={nav?'fixed top-0 left-0 w-[60%] flex flex-col z-30 sm:hidden bg-precision bg-opacity-75 ': 'hidden'}>
            <nav className='duration-300'> 
            <ul className='flex flex-col p-4 text-white bg-black bg-opacity-25'>
                    <li className='text-m py-2 flex items-center border-b-slate-50'>
                        <Link to={`/home`} className='p-1 flex items-center justify-between cursor-pointer'> 
                            Cloud
                        </Link>
                    </li>
                    <li className='text-m py-2 flex items-center'>
                        <Link to={`/booking`} className='p-1 flex items-center justify-between cursor-pointer'>
                            AI
                        </Link>
                    </li>
                    <li className='text-m py-2 flex items-center'>
                        <Link to={`/catalogue/`} className='p-1 flex items-center justify-between cursor-pointer'>
                            Solutions
                        </Link>
                    </li>
                    <li className='text-m py-2 flex items-center'>
                        <Link to={`/catalogue/`} className='p-1 flex items-center justify-between cursor-pointer'>
                        Resources
                        </Link>
                    </li>
                    <li className='text-m py-2 flex items-center'>
                        <Link to={`/catalogue/`} className='p-1 flex items-center justify-between cursor-pointer'>
                        Partners
                        </Link>
                    </li>
                    <li className='text-m py-2 flex items-center'>
                        <Link to={`/catalogue/`} className='p-1 flex items-center justify-between cursor-pointer'>
                        Contact
                        </Link>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
    <div className={solutionsNav?`animate-fadeInMenu z-50 bg-precision absolute inset-[20%] w-50 top-20 w-[60%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3`:`hidden`}>
                    {
                        services.map((s)=><div className='bg-[#030527] text-[#3c70b1] hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105 flex flex-col justify-between items-center rounded-lg border border-white/[0.05] p-4 shadow-md md:h-[350px]'>
                        <div className='flex justify-evenly'>
                        {s.icon?s.icon:null}
                        <h5 className='text-lg ml-2'>{s.name}</h5>
                        </div>  
                        <p className='text-sm'>{s.desc}</p>
                        <button className='text-white text-sm bg-[#030527] h-[50px] w-[60%]'>{s.button}</button>
                    </div>)
                    }
              
    </div>
    <div className={prodNav?`animate-fadeInMenu z-50 bg-precision absolute inset-[20%] w-50 top-20 w-[60%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3`:`hidden`}>
                    {
                        services.map((s)=><div className='bg-precision text-white hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105 flex flex-col justify-between items-center rounded-lg border border-white/[0.05]  p-4 shadow-md md:h-[350px]'>
                        <div className='flex justify-evenly'>
                        {s.icon?s.icon:null}
                        <h5 className='text-lg ml-2'>{s.name}</h5>
                        </div>  
                        <p className='text-sm'>{s.desc}</p>
                        <button className='text-white text-sm bg-[#030527] h-[50px] w-[60%]'>{s.button}</button>
                    </div>)
                    }
              
    </div>
    
    <div className={resourcesNav?`animate-fadeInMenu z-50 bg-precision absolute inset-[20%] w-50 top-20 w-[60%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3`:`hidden`}>
                    {
                        services.map((s)=><div className='bg-precision text-white hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105 flex flex-col justify-between items-center rounded-lg border border-white/[0.05]  p-4 shadow-md md:h-[350px]'>
                        <div className='flex justify-evenly'>
                        {s.icon?s.icon:null}
                        <h5 className='text-lg ml-2'>{s.name}</h5>
                        </div>  
                        <p className='text-sm'>{s.desc}</p>
                        <button className='text-white text-sm bg-[#030527] h-[50px] w-[60%]'>{s.button}</button>
                    </div>)
                    }
              
    </div>
    
    <div className={aiNav?`animate-fadeInMenu z-50 bg-precision absolute inset-[20%] w-50 top-20 w-[60%]  md:text-m justify-evenly grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3`:`hidden`}>
                    {
                        services.map((s)=><div className='bg-precision text-white hover:shadow-[0_10px_20px_rgba(110,231,183,0.5)] transform transition-transform duration-200 hover:scale-105 flex flex-col justify-between items-center rounded-lg border border-white/[0.05]  p-4 shadow-md md:h-[350px]'>
                        <div className='flex justify-evenly'>
                        {s.icon?s.icon:null}
                        <h5 className='text-lg ml-2'>{s.name}</h5>
                        </div>  
                        <p className='text-sm'>{s.desc}</p>
                        <button className='text-white text-sm bg-[#030527] h-[50px] w-[60%]'>{s.button}</button>
                    </div>)
                    }
              
    </div>
    
    </div>
    
  )
}
