import { SiKubernetes } from "react-icons/si";
import { FaCloud } from "react-icons/fa";
import { GiPlatform } from "react-icons/gi";
import { GiOffshorePlatform } from "react-icons/gi";
import { GiServerRack } from "react-icons/gi";
import { GrDeploy } from "react-icons/gr";
import { FcDeployment } from "react-icons/fc";
import { MdOutlineSecurity } from "react-icons/md";

const insights = [
    {
        name:'Multi-cluster K8s with Cilium',
        desc:'Elevate your software development with our DevOps expertise. Unlock rapid deployment, enhanced collaboration, and unmatched scalability to deliver exceptional products efficiently.',
        image:'cilium-seeklogo.svg',
        button:'Read More',
        author:'Chimwemwe Chinamale',
        author_image:'chimwemwe.jpg',
        theme:'Kubernetes',
        date:'18-09-2024'
    },
    {
        name:'Multi-cloud Architecture with Terraform',
        desc:'Elevate your software development with our DevOps expertise. Unlock rapid deployment, enhanced collaboration, and unmatched scalability to deliver exceptional products efficiently.',
        image:'terraform.png',
        button:'Read More',
        author:'Chimwemwe Chinamale',
        author_image:'chimwemwe.jpg',
        theme:'Kubernetes',
        date:'18-09-2024'
    },{
        name:'Bulding internal developer Platforms',
        desc:'Elevate your software development with our DevOps expertise. Unlock rapid deployment, enhanced collaboration, and unmatched scalability to deliver exceptional products efficiently.',
        image:<FcDeployment />,
        button:'Read More',
        author:'Chimwemwe Chinamale',
        author_image:'chimwemwe.jpg',
        theme:'Kubernetes',
        date:'18-09-2024'
    },{
        name:'Chaos Experiments at Scale',
        desc:'Elevate your software development with our DevOps expertise. Unlock rapid deployment, enhanced collaboration, and unmatched scalability to deliver exceptional products efficiently.',
        image:<FcDeployment />,
        button:'Read More',
        author:'Chimwemwe Chinamale',
        author_image:'chimwemwe.jpg',
        theme:'Kubernetes',
        date:'18-09-2024'
    },{
        name:'Kubernetes Security with Falco',
        desc:'Elevate your software development with our DevOps expertise. Unlock rapid deployment, enhanced collaboration, and unmatched scalability to deliver exceptional products efficiently.',
        image:'falco.png',
        button:'Read More',
        author:'Chimwemwe Chinamale',
        author_image:'chimwemwe.jpg',
        theme:'Kubernetes',
        date:'18-09-2024'
    },{
        name:'Advanced Systems Stability',
        desc:'Elevate your software development with our DevOps expertise. Unlock rapid deployment, enhanced collaboration, and unmatched scalability to deliver exceptional products efficiently.',
        image:<FcDeployment />,
        button:'Read More',
        author:'Chimwemwe Chinamale',
        author_image:'chimwemwe.jpg',
        theme:'Kubernetes',
        date:'18-09-2024'
    },
    
]


export default insights